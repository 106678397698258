import {
    AutocompleteInput,
    Create,
    Edit,
    NumberInput,
    ReferenceInput,
    required,
    TabbedForm,
    TextInput
} from 'react-admin';

const form = (
    <TabbedForm>
        <TabbedForm.Tab label='Контент'>
            <TextInput label='Аббревиатура' source='abbreviation' fullWidth validate={required()} />
            <ReferenceInput source='organizationId' reference='organization' sortBy='name'>
                <AutocompleteInput
                    optionText='name'
                    label='Организация'
                    filterToQuery={(searchText: string) => ({name: searchText})}
                    fullWidth
                />
            </ReferenceInput>
            <TextInput label='Полное наименование' source='fullName' fullWidth validate={required()} />
            <TextInput label='Адрес здания' source='address' fullWidth validate={required()} />

            <ReferenceInput source='districtId' reference='district' sortBy='name'>
                <AutocompleteInput
                    optionText='name'
                    label='Округ'
                    filterToQuery={(searchText: string) => ({name: searchText})}
                    fullWidth
                />
            </ReferenceInput>
            <ReferenceInput source='territorialUnitId' reference='territorial-unit' sortBy='name'>
                <AutocompleteInput
                    optionText='name'
                    label='ТО'
                    filterToQuery={(searchText: string) => ({name: searchText})}
                    fullWidth
                />
            </ReferenceInput>
            <NumberInput label='Порядок сортировки' source='hasOrder' fullWidth />
        </TabbedForm.Tab>
    </TabbedForm>
);

export const DepartmentCreate = () => {
    return <Create>{form}</Create>;
};

export const DepartmentEdit = () => {
    return <Edit>{form}</Edit>;
};
