import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Datagrid,
    DateField,
    DateTimeInput,
    Edit,
    EditButton,
    FunctionField,
    ReferenceArrayInput,
    ReferenceInput,
    ReferenceManyField,
    TabbedForm,
    TextField,
    TextInput,
    useNotify
} from 'react-admin';
import {CounterInput, MediaImageInput, RichTextInput} from 'components';
import {getUserFullName} from 'utils';
import {PinComponent, StatusComponent, VideoComponent} from './components';
import React, {useState} from 'react';

export default () => {
    const notify = useNotify();
    const [clearVideo, setClearVideo] = useState(false);

    return (
        <Edit
            transform={data => ({...data, videoFile: clearVideo ? null : data.videoFile})}
            mutationMode='pessimistic'
            mutationOptions={{
                onError: (error: any) => {
                    switch (error?.message) {
                        case 'PINNED_STORIES_LIMIT':
                            notify(
                                'Превышен лимит закрепленных историй (3 истории). Пожалуйста, открепите одну из историй',
                                {type: 'error'}
                            );
                            break;
                        case 'INCOMPATIBLE_STORY_STATUS':
                            notify('История должна быть в статусе "Опубликована"', {type: 'error'});
                            break;
                    }
                }
            }}>
            <TabbedForm>
                <TabbedForm.Tab label='Контент'>
                    <TextInput label='№' source='id' fullWidth disabled />
                    <ReferenceArrayInput source='authorIds' reference='user'>
                        <AutocompleteArrayInput
                            label='Авторы'
                            filterToQuery={(searchText: any) => ({search: searchText})}
                            optionText={(record) =>
                                getUserFullName(record) + (record.jobPost?.name ? ` (${record.jobPost?.name})` : '')
                            }
                            fullWidth
                        />
                    </ReferenceArrayInput>
                    <MediaImageInput label='Изображение' source='file' />
                    <VideoComponent clearVideo={clearVideo} setClearVideo={setClearVideo}/>
                    <CounterInput label='Заголовок' source='title' fullWidth maxLength={150} />
                    <ReferenceInput source='departmentId' reference='department' sortBy='abbreviation'>
                        <AutocompleteInput
                            label='Подразделение'
                            fullWidth
                            optionText={(record) => record.abbreviation + ' – ' + record.fullName}
                            filterToQuery={(searchText: any) => ({
                                abbreviation: searchText
                            })}
                        />
                    </ReferenceInput>
                    <ReferenceArrayInput source='availableOrganizationIds' reference='organization'>
                        <AutocompleteArrayInput
                            label='Организации'
                            fullWidth
                            optionText='name'
                            filterToQuery={(searchText: any) => ({name: searchText})}
                        />
                    </ReferenceArrayInput>
                    <RichTextInput label='Описание' source='description' />
                    <DateTimeInput label='Дата создания' source='createdAt' fullWidth />
                    <StatusComponent />
                    <PinComponent />
                </TabbedForm.Tab>

                <TabbedForm.Tab label='Комментарии'>
                    <ReferenceManyField reference='comment' target='storyId'>
                        <Datagrid sx={{width: '100%'}}>
                            <TextField label='№' source='id' />
                            <FunctionField
                                label='Пользователь'
                                render={(record: any) => getUserFullName(record.author)}
                            />
                            <TextField label='Текст' source='text' />
                            <DateField label='Дата создания' source='createdAt' showTime />
                            <EditButton />
                        </Datagrid>
                    </ReferenceManyField>
                </TabbedForm.Tab>

                <TabbedForm.Tab label='Теги'>
                    <ReferenceArrayInput source='tagIds' reference='tag'>
                        <AutocompleteArrayInput
                            label='Теги'
                            optionText='name'
                            fullWidth
                            filterToQuery={(searchText: any) => ({name: searchText})}
                        />
                    </ReferenceArrayInput>
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>
    );
};
