import {useWatch} from 'react-hook-form';
import {
    AutocompleteInput,
    BooleanInput,
    DateInput,
    FileField,
    FileInput,
    Labeled,
    minValue,
    ReferenceInput,
    useRecordContext
} from 'react-admin';
import {getEnumArray, STORY_STATUS_ENUM} from 'utils';
import moment from 'moment';
import {IconButton, Stack} from '@mui/material';
import React from 'react';
import {Clear as ClearIcon} from '@mui/icons-material';

export const StatusComponent = () => {
    const watchStatus = useWatch({name: 'status'});
    return (
        <>
            <AutocompleteInput
                label='Статус'
                source='status'
                choices={getEnumArray(STORY_STATUS_ENUM)}
                fullWidth
                optionText='name'
                defaultValue={'UNDER_CONSIDERATION'}
            />
            {watchStatus === 'MODERATION_FAILED' && (
                <ReferenceInput source='rejectionReasonId' reference='rejection-reason'>
                    <AutocompleteInput
                        label='Причина отклонения'
                        optionText='text'
                        fullWidth
                        filterToQuery={(searchText: any) => ({text: searchText})}
                    />
                </ReferenceInput>
            )}
        </>
    );
};

export const PinComponent = () => {
    const watchIsPinned = useWatch({name: 'isPinned'});
    return (
        <>
            <BooleanInput label='Закрепить' source='isPinned' />
            {watchIsPinned && (
                <DateInput
                    label='Закрепить до'
                    source='pinnedTo'
                    fullWidth
                    validate={minValue(moment().format('YYYY-MM-DD'), 'Вы не можете указать прошедшую дату')}
                />
            )}
        </>
    );
};

export const VideoComponent = ({clearVideo, setClearVideo}: any) => {
    const record = useRecordContext();

    return (
        <>
            {record?.videoFile && !clearVideo ? (
                <Labeled label={'Видео'} sx={{marginBottom: 3}}>
                    <Stack gap={2} direction='row' alignItems='flex-start'>
                        <video controls src={record?.videoFile.url} width={400} />
                        <IconButton onClick={() => setClearVideo(true)} color='error'>
                            <ClearIcon />
                        </IconButton>
                    </Stack>
                </Labeled>
            ) : (
                <FileInput source='video' label='Видео' maxSize={20000000} accept="video/mp4, video/webm">
                    <FileField source='src' title='title' />
                </FileInput>
            )}
        </>
    );
};
