import {
    AutocompleteInput,
    Datagrid,
    ExportButton,
    FilterButton,
    FunctionField,
    List,
    TextField,
    TextInput,
    TopToolbar
} from 'react-admin';
import {getEnumArray, getUserFullName, USER_REQUEST_ENUM_MAP} from 'utils';

const filters = [
    <TextInput label='Название' source='title' />,
    <AutocompleteInput label='Статус' source='status' choices={getEnumArray(USER_REQUEST_ENUM_MAP)} optionText='name' />
];

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

export default () => {
    return (
        <List actions={<ListActions />} filters={filters} sort={{field: 'id', order: 'desc'}} emptyWhileLoading>
            <Datagrid rowClick='edit'>
                <TextField label='№' source='id' />
                <TextField label='Тема обращения' source='title' />
                <FunctionField label='Пользователь' render={(record: any) => getUserFullName(record.user)} />
                <FunctionField
                    label='Статус'
                    source='status'
                    render={(record: any) => USER_REQUEST_ENUM_MAP[record?.status]}
                />
            </Datagrid>
        </List>
    );
};
