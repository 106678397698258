export const STORY_STATUS_ENUM = {
    // DRAFT: 'Черновик',
    PUBLISHED: 'Опубликовано',
    MODERATION_FAILED: 'Не прошла модерацию',
    UNDER_CONSIDERATION: 'На модерации'
};

export const ROLE_ENUM = {
    USER: 'Пользователь',
    ADMIN: 'Администратор',
    MODERATOR: 'Модератор'
};

export enum USER_REQUEST_ENUM {
    PROCESSED= 'PROCESSED',
    UNDER_CONSIDERATION = 'UNDER_CONSIDERATION'
};
export const USER_REQUEST_ENUM_MAP = {
    PROCESSED: 'Обработано',
    UNDER_CONSIDERATION: 'На рассмотрении'
};