import {
    AutocompleteInput,
    Button,
    DateInput,
    Edit,
    FileField,
    FileInput, FunctionField, Labeled, ReferenceInput,
    SaveButton,
    TabbedForm,
    TextInput,
    Toolbar,
    useRecordContext
} from 'react-admin';
import React from 'react';
import {Download as DownloadIcon} from '@mui/icons-material';
import saveAs from 'file-saver';
import {getUserFullName, USER_REQUEST_ENUM} from 'utils';

const Form = () => {
    const record = useRecordContext();
    return (
        <>
            <TextInput label='№' source='id' fullWidth disabled />
            <Labeled label='Пользователь' style={{marginBottom: 10}}>
                <FunctionField render={(record: any) => getUserFullName(record.user)} />
            </Labeled>
            <TextInput label='Тема обращения' source='title' fullWidth disabled />
            <TextInput
                label='Текст обращения'
                source='userMessage.content'
                fullWidth
                disabled
                multiline
                minRows={5}
                maxRows={5}
            />

            {record.userMessage?.file && (
                <Button
                    label={record.userMessage?.file?.name}
                    onClick={() => {
                        if (record.userMessage.file.url) {
                            saveAs(record.userMessage?.file.url, record.userMessage?.file.name || 'download');
                        }
                    }}>
                    <DownloadIcon />
                </Button>
            )}
            <DateInput label='Дата обращения' source='createdAt' fullWidth disabled />
            <TextInput
                label='Ответ'
                source='adminMessage.content'
                fullWidth
                multiline
                minRows={5}
                maxRows={5}
                required
                disabled={record.status === USER_REQUEST_ENUM.PROCESSED}
            />
            {record.status === USER_REQUEST_ENUM.PROCESSED && record.adminMessage?.file && (
                <Button
                    label={record.adminMessage?.file?.name}
                    onClick={() => {
                        if (record.adminMessage.file.url) {
                            saveAs(record.adminMessage?.file.url, record.adminMessage?.file.name || 'download');
                        }
                    }}>
                    <DownloadIcon />
                </Button>
            )}
            {record.status === USER_REQUEST_ENUM.UNDER_CONSIDERATION && (
                <FileInput source='file' label='Файл'>
                    <FileField source='src' title='title' />
                </FileInput>
            )}
        </>
    );
};
export const UserRequestEdit = () => {
    return (
        <Edit transform={(data) => ({content: data.adminMessage?.content, file: data?.file})}>
            <TabbedForm
                toolbar={
                    <Toolbar>
                        <SaveButton />
                    </Toolbar>
                }>
                <TabbedForm.Tab label='Контент'>
                    <Form />
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>
    );
};
